//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { rootStore } from '@/store'
import TaskCardMixin from './TaskCardMixin'
import BaseEntityAvatar from '@/components/UI/BaseEntityAvatar.vue'

export default {
  name: 'TaskBarCardCompact',
  components: {
    BaseEntityAvatar,
    IconPhoneVolume: () => import('@/components/UI/icons/IconPhoneVolume.vue'),
  },
  mixins: [TaskCardMixin],
  props: {
    jid: String,
    taskObject: Object,
    standalone: Boolean,
    showDir: Boolean,
    compact: Boolean,
    quickDone: Boolean,
    notHoverable: Boolean,
    dragMetadata: Object,
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['rightBar', 'taskDesk'].indexOf(value) !== -1
      },
    },
  },
  data () {
    return {
      hovered: false,
    }
  },
  computed: {
    callStatus () {
      return !!rootStore.getters.callStatuses[this.task.jid]
    },
    hasNotification () {
      return this.wasNoticed || this.hasUnread
    },
    // TODO: bring back when decided on behaviour
    // neverRead () {
    //   let chat = this.$store.getters.chat(this.task.jid)
    //   return chat && chat.lastReadMessageId == null
    // },
    // TODO: bring back when decided on behaviour
    // isAssigned () {
    //   let me = this.$store.getters.getUserId
    //   return this.task.assignee === me
    // },
    // TODO: bring back when decided on behaviour
    // indicator () {
    //   if (this.isAssigned) return 'assignee'
    //   if (this.task.owner === this.$store.getters.getUserId) return 'owner'
    //   return 'observer'
    // },
    // TODO: bring back when decided on behaviour
    // onlyMe () { // в задаче есть только я
    //   let me = this.$store.getters.getUserId
    //   return this.task.observers.length === 0 && (this.task.assignee === me || this.task.assignee === null) && this.task.owner === me
    // },
    // TODO: bring back when decided on behaviour
    // isInCopy () {
    //   let me = this.$store.getters.getUserId
    //   return this.task.assignee !== me && this.task.owner !== me
    // },
    // TODO: bring back when decided on behaviour
    // hasManyAssignees () {
    //   return this.task.assignee === null && this.task.observers.length > 0
    // },
    // TODO: bring back when decided on behaviour
    // isNew () {
    //   return this.task.taskStatus === 'new' && this.task.numUnread > 0
    // },
    // TODO: bring back when decided on behaviour
    // canChangeStatus () {
    //   return this.task.changeableFields.indexOf('task_status') !== -1
    // },
  },
  methods: {
    // TODO: uncomment when ellipsis (...) content is decided upon
    // editTask () {
    //   this.$store.dispatch('SHOW_MODAL', { instance: 'new-task', payload: { jid: this.task.jid } })
    // },
    // dragStart (ev) {
    //   const data = {
    //     ...this.dragMetadata || {},
    //     num: this.task.num,
    //     jid: this.task.jid
    //   }

    //   this.$store.dispatch(actionTypes.TASK_DRAG_DATA, { data })
    //   ev.dataTransfer.effectAllowed = 'all'
    //   ev.dataTransfer.setData('tadateam/x-task', JSON.stringify(data))
    //   ev.dataTransfer.setDragImage(ev.target, 203, 50)
    //   return true
    // },
    // ?: unused?
    // changeStatus () {
    //   this.$store.dispatch('TASK_CHANGE_STATUS', { jid: this.task.jid })
    // },
    // TODO: uncomment when ellipsis (...) content is decided upon
    // openFullTask (ev) {
    //   if (ev.ctrlKey) {
    //     return
    //   }
    //   if (ev.shiftKey) {
    //     api.tasks.edit(this.task.jid, { pinned: !this.task.pinned, pinned_sort_ordering: 0 })
    //     ev.preventDefault()
    //     return
    //   }
    //   ev.preventDefault()

    //   !this.standalone && this.$store.dispatch('OPEN_CHAT', { chatId: !this.isThisTaskOnScreen ? this.task.jid : null })
    // },
    // ?: unused?
    // pinMove (ev) {
    //   let index = this.task.pinnedSortOrdering + (ev.shiftKey ? -1 : 1)
    //   api.tasks.edit(this.task.jid, { pinned: true, pinned_sort_ordering: index })
    // }
  },
}
